import { Box, Button, Typography } from "@mui/material";
import styled from "@emotion/styled";

export const SBox1 = styled(Box)({
    background: '#FFFFFF',
    width: '80%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '30px',
    marginBottom: '30px',
})

export const STypography1 = styled(Typography)({
    color: '#FFFFFF',
    fontFamily: "Inter",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "30px",
    padding: '32px'
})

export const SBox2 = styled(Box)({
    background: "#FFFFFF",
    boxShadow: "0px 2px 6px rgba(59, 80, 146, 0.25)",
    borderRadius: "10px",
    maxWidth: "1050px",
    marginTop: '20px',
    marginBottom:'20px',
    marginLeft: "auto",
    marginRight: "auto",
    padding: "15px",
})

export const SBox3 = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: 'center',
    padding: '8px',
})

export const STypography2 = styled(Typography)({
    fontFamily: "Merriweather",
    fontSize: "36px",
    fontWeight: 500,
    lineHeight: "45.25px",
    color: '#226447'
});

export const STypography3 = styled(Typography)({
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "17.64px",
    fontStyle: "normal",
});

export const STypography4 = styled(Typography)({
    fontFamily: "Inter",
    fontSize: "15px",
    fontWeight: 500,
    lineHeight: "19.36px",
    fontStyle: "normal",
    margin: '0px',
    marginBottom: '8px',
    padding: '0px'
});

export const SBox4 = styled(Box)({
    textAlign: "left",
    margin: "8px",
    marginBottom: "0px",
    marginTop: "0px"
});

export const STypography5 = styled(Typography)({
    fontFamily: "Inter",
    fontSize: "13px",
    color: "#DE5726"
});

export const STypography6 = styled(Typography)({
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "16.94px",
    fontStyle: "normal",
});

export const SButton1 = styled(Button)({
    background: "#FF7A00",
    border: "1px solid #FF7A00",
    boxShadow: "0px 2px 6px rgba(59, 80, 146, 0.25)",
    color: "#FFFFFF",
    borderRadius: "5px",
    textTransform: "none",
    padding: "16px",
    paddingTop: "12px",
    paddingBottom: "12px",
    margin: "8px",
    width: "233px",
    "&:hover": {
        background: "#FF7A00",
        border: "1px solid #FF7A00",
    },
});

export const SButton2 = styled(Button)({
    background: "#FFFFFF",
    border: "2px solid #FFFFFF",
    color: "#FF7A00",
    borderRadius: "5px",
    boxShadow: "0px 2px 6px rgba(59, 80, 146, 0.25)",
    textTransform: "none",
    padding: "16px",
    paddingTop: "12px",
    paddingBottom: "12px",
    margin: "8px",
    width: "233px",
    "&:hover": {
        background: "#FFFFFF",
    },
});

export const SButton3 = styled(Button)({
    background: "#FF7A00",
    border: "1px solid #FF7A00",
    boxShadow: "0px 2px 6px rgba(59, 80, 146, 0.25)",
    color: "#FFFFFF",
    borderRadius: "5px",
    textTransform: "none",
    padding: "16px",
    paddingTop: "12px",
    paddingBottom: "12px",
    margin: "8px",
    "&:hover": {
        background: "#FF7A00",
        border: "1px solid #FF7A00",
    },
});

export const SBox5 = styled(Box)({
    padding: '8px',
    paddingTop: '0px',
    paddingBottom: '16px',
    textAlign: 'left'
})

export const SBox7 = styled(Box)({
    background: '#FFFFFF',
    width: '80%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '30px',
    marginBottom: '30px',
})

export const STypography7 = styled(Typography)({
    fontFamily: "Inter",
    fontSize: "15px",
    fontWeight: 400,
    fontStyle: "normal",
    margin: '0px',
    marginBottom: '8px',
    padding: '0px'
});

export const STypography8 = styled(Typography)({
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "32px",
    fontStyle: "normal",
    color: '#05004E',
    margin: '0px',
    marginBottom: '8px',
    padding: '0px'
});

export const STypography9 = styled(Typography)({
    fontFamily: "Inter",
    fontSize: "15px",
    fontWeight: 400,
    lineHeight: "16.94px",
    fontStyle: "normal",
    color: '#818181',
    margin: '0px',
    marginBottom: '8px',
    padding: '16px',
    paddingTop: '8px',
    paddingBottom: '8px'
});

export const STypography10 = styled(Typography)({
    fontFamily: "Inter",
    fontSize: "15px",
    fontWeight: 600,
    lineHeight: "19.36px",
    fontStyle: "normal",
    margin: '0px',
    paddingTop: '16px',
    paddingBottom: '16px'
});

export const STypography11 = styled(Typography)({
    fontFamily: "Poppins",
    fontSize: "18px",
    fontWeight: 600,
    fontStyle: "normal",
    margin: '0px',
    paddingTop: '16px',
    paddingBottom: '16px',
    color: '#FF7A00'
});

export const SBoxN1 = styled(Box)({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '70px'
})

export const SBoxN2 = styled(Box)({
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '70px'
})
