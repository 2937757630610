/**
 * Form Elemets
 */
import React, { useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet";
import moment from "moment";
import { Link, useSearchParams } from "react-router-dom";
import {
  job_location,
  talukas,
  socialData,
  fav,
  salary_range,
} from "../../constants.js";
import watsapp from "../../assets/img/profiles/watsapp.png";
import cover from "../../assets/img/profiles/website-banner.jpg";
import LoadingSpinner from "./LoadingSpinner/LoadingSpinner.jsx";
import cover1 from "../../assets/img/profiles/SBA-003.jpg";
import jobPDF from "../../assets/address.pdf";
import deleteicon from "../../assets/img/profiles/delete-bin-line.svg";
import styled from "@emotion/styled";
import { Box, Grid, TextField, Typography, Stepper, StepLabel, Step, Button, useMediaQuery } from '@mui/material'
import { SBox1, SBox2, SBox3, SBox4, SBox5, SBox7, SBoxN1, SBoxN2, SButton1, SButton2, SButton3, STypography1, STypography10, STypography11, STypography2, STypography3, STypography4, STypography5, STypography6, STypography7, STypography8, STypography9 } from '../../assets/style/Styles.js'
import Select from "react-select"
import { useFormik } from "formik"
import * as Yup from "yup"
import img1 from '../../assets/images/imgOne.png'
import img2 from '../../assets/images/imgTwo.png'
import img4 from '../../assets/images/imgFour.png'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import {
  skills,
  sector,
  gender,
  taluka,
  specialization,
  grad_data,
  category,
  dist,
  maritalStatus,
  partTime,
  priorities,
  rank,
  serviceType,
  experience,
  //languageList,
  opportunities
} from "../../constants.js";
import axios from "axios";
import { checkPropTypes } from "prop-types";
import Swal from "sweetalert2";

const HorizontalForm = () => {
  const ref = useRef();
  const [show, setShow] = useState(false)
  const [resumeshow, setResumeshow] = useState(false)
  const [resumeshow1, setResumeshow1] = useState(0)
  const [obj, setObj] = useState({
    org_address: "",
    org_state: "",
    org_district: "",
    taluka: "",
    org_pincode: "",
    mobnum: "",
    email: "",
    firstnm: "",
    lastnm: "",
    marital_status: "",
    gender: "",
    birth_date: ""
  });
  const [statenew, setStateNew] = useState([]);
  const [districtnew, setDistrictNew] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [submitform, setSubmitform] = useState(false);
  const [submitformOne, setSubmitformOne] = useState(false);
  const [nextStep, setNextStep] = useState(false)
  const [regId, setRegId] = useState("")
  const [preffere, setPreffere] = useState([]);
  const [resumefile, setResumeFile] = useState("");
  const date = new Date();
  const newdate = `${String(date.getDate()).padStart(2, "0")}-${String(
    date.getMonth() + 1
  ).padStart(2, "0")}-${date.getFullYear()}`;

  const [searchParams, setSearchParams] = useSearchParams();
  const [centerId, setCenterId] = useState("");
  const [centerHeadId, setCenterHeadId] = useState("");
  const [centerEmployeeId, setCenterEmployeeId] = useState("");
  const [languagesall, setLanguagesall] = useState([])
  const [languageList, setLanguageList] = useState([])
  const matchmd = useMediaQuery('max-width:700px')
  const matchsm = useMediaQuery('(min-width:600px)')
  const [formDetails, setFormDetails] = useState({
    firstname: "",
    lastname: "",
    //fathername: "",
    //mothername: "",
    religion: "",
    mother_tongue: "",
    email: "",
    date_of_birth: "",
    gender: "",
    height: "",
    address: "",
    pincode: "",
    state: "",
    desired_salary: "",
    current_salary: "",
    priority: "",
    district: "",
    taluka: "",
    highest_education: "",
    degree: "",
    stream: "",
    mobile: "",
    cast: "",
    des_industry: "",
    job_Location: "",
    job_role: "",
    // sub_cast: "",
    know_us: "",
    marital_status: "",
    aadhar: "",
    part_time: "",
    experience: "",
    // job_fair_location: "",
    current_industry: "",
    // preffered_work: "",
    skills: "",
    self_opportunity: "",
    wa_optin: "false",
    // center_id: "",
    // center_head: "",
    // center_employee: "",
    // stay_mah: {
    //   year: null,
    //   month: null,
    // },
    // if_disabled: {
    //   nature: "",
    //   percentage: null,
    // },
    // ex_serviceman: {
    //   type: "",
    //   rank: "",
    //   total_service: null,
    // },
    languages: {
      marathi: {
        speak: false,
        read: false,
        write: false,
        fluency: false,
      },
      hindi: {
        speak: false,
        read: false,
        write: false,
        fluency: false,
      },
      english: {
        speak: false,
        read: false,
        write: false,
        fluency: false,
      },
    },
    // wiling_for: {
    //   state_police_service: false,
    //   state_reserve_police_force: false,
    //   armed_force: false,
    //   private_security_services: false,
    //   sales_service: false,
    //   type_of_agents: false,
    //   sweeper_cleaner: false,
    //   shop_customer_services: false,
    // },
    // qualification_and_training: [
    //   {
    //     exam_passed: "",
    //     year: null,
    //     medium: "",
    //     percentage: null,
    //   },
    // ],
  });
  // const [joblocation, setjobLocation] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [check, setCheck] = useState(true);
  const [desi, setDesi] = useState([]);
  // const [state, setState] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const steps = ['Personal Information', 'Career Profile Information'];
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen1 = () => {
    setOpen1(true);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const stepperStyle = {
    "& .Mui-active": {
      "&.MuiStepIcon-root": {
        color: "#FF7A00",
      },
    },
    "& .Mui-completed": {
      "&.MuiStepIcon-root": {
        color: "#FF7A00",
      },
    }
  }

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  // const [workExp, setWorkExp] = useState([
  //   {
  //     employer_name: "",
  //     designation: "",
  //     job_type: "",
  //     joining_date: "",
  //     relieving_date: "",
  //     totel_exp: null,
  //   },
  // ]);

  // const [educationQ, setEducationQ] = useState([
  //   {
  //     exam_passed: "",
  //     year: null,
  //     medium: "",
  //     percentage: null,
  //   },
  // ]);
  const SBox6 = styled(Box)(matchmd ?
    {
      p: 3,
      ml: 'auto',
      mr: 'auto',
      background: 'rgba(212, 229, 255, 0.45)',
      borderRadius: '9px',
      border: '3px dashed rgba(45, 91, 255, 1)',
      width: '570px',
      minHeight: '185px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      cursor: 'pointer'
    } :
    {
      p: 3,
      ml: 'auto',
      mr: 'auto',
      background: 'rgba(212, 229, 255, 0.45)',
      borderRadius: '9px',
      border: '3px dashed rgba(45, 91, 255, 1)',
      maxWidth: '570px',
      minHeight: '185px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      cursor: 'pointer'
    })

  {
    /************ */
  }
  // const base_url =
  //   "https://i60vrmt4cl.execute-api.ap-south-1.amazonaws.com/dev";
  const base_url = process.env.REACT_APP_BACKEND_GENERIC_PROD_URL;

  const uat_url = process.env.REACT_APP_BACKEND_GENERIC_URL;
  // "https://qgyoznpzm9.execute-api.ap-south-1.amazonaws.com/uat-present";
  {
    /*Options data */
  }
  const [genderOption, setGenderOption] = useState([]);
  const [educationOption, setEducationOption] = useState([]);
  const [degreeOption, setDegreeOption] = useState([]);
  const [streamOption, setStreamOption] = useState([]);
  const [salaryList, setSalaryList] = useState([]);
  const [industryOption, setIndustryOption] = useState([]);
  const [casteOption, setCasteOption] = useState([]);
  const [experienceOption, setExperienceOption] = useState([]);
  const [maritalstatusList, setMaritalStatusList] = useState([]);
  const [skillsList, setSkillsList] = useState([]);
  const [State, setState] = useState([]);
  const [District, setDistrict] = useState([]);
  const [Taluka, setTaluka] = useState([]);
  const [jobRoleList, setJobRoleList] = useState([]);
  const [jobLocationList, setJobLocationList] = useState([]);
  const [verifyStatus, setVerifyStatus] = useState(false);
  // const [centersList,setCentersList] = useState([]);
  // const [centerHeadList,setCenterHeadList] = useState([]);
  // const [centerEmployeeList,setCenterEmployeeList] = useState([]);

  //// education,degree,stream,jobrole,joblocation,state,district,taluka
  {
    /*Options data */
  }

  const fetchData1 = async () => {
    try {
      const response = await axios.get(
        "https://7e55uus5l5.execute-api.ap-south-1.amazonaws.com/dev/desg"
      );
      const data = response.data;
      setDesi(
        Object.values(data.name).map((ele) => ({
          label: ele,
          value: ele,
        }))
      );
    } catch (err) {
      console.log(err);
    }
  };

  // const fetchCenterHead = async ()=>{
  //   axios.get(`${base_url}/centerhead/master/details`)
  //   .then((res)=>{
  //     console.log("cH data",res.data["center_head_details"])
  //     setCenterHeadList(res.data["center_head_details"])
  //   })
  //   .catch((err)=>{
  //     console.log("error ch",err.response);
  //   })
  // }
  // const fetchCenters = async ()=>{
  //   axios.get(`${base_url}/jobcenter/master/details`)
  //   .then((res)=>{
  //     console.log("center data",res.data["job_center_details"])
  //     setCentersList(res.data["job_center_details"])
  //   })
  //   .catch((err)=>{
  //     console.log("error center",err.response);
  //   })
  // }
  // const fetchCenterEmployee = async ()=>{
  //   axios.get(`${base_url}/centeremployee/master/details`)
  //   .then((res)=>{
  //     console.log("center data",res.data["center_employee_details"])
  //     setCenterEmployeeList(res.data["center_employee_details"]);
  //   })
  //   .catch((err)=>{
  //     console.log("error center",err.response);
  //   })
  // }

  const fetchState = async () => {
    axios
      .get(`${base_url}/generic/state`)
      .then((res) => {
        console.log("state", res.data);
        setState(res.data);
      })
      .catch((e) => {
        console.log("state", e);
      });
  };
  console.log(selectedState, "self")

  const fetchDistrict = async () => {
    axios
      .get(`${base_url}/generic/district`, {
        params: { state: selectedState },
      })
      .then((res) => {
        console.log("district", res.data);
        setDistrict(res.data);
      })
      .catch((err) => {
        console.log("district", err);
      });
  };

  const syncWait = ms => {
    const end = Date.now() + ms
    while (Date.now() < end) continue
  }

  const fetchTaluka = async () => {
    axios
      .get(`${base_url}/generic/taluka`, {
        params: {
          state: formDetails.state,
          district: formDetails.district,
        },
      })
      .then((res) => {
        console.log("taluka", res.data);
        setTaluka(res.data);
      })
      .catch((err) => {
        console.log("taluka", err);
      });
  };

  const fetchDegree = async () => {
    axios
      .get(`${base_url}/generic/degree`)
      .then((res) => {
        console.log("Degree", res.data);
        setDegreeOption(res.data);
      })
      .catch((e) => {
        console.log("degree", e);
      });
  };
  const fetchStream = async () => {
    axios
      .get(`${base_url}/generic/stream`)
      .then((res) => {
        console.log("Stream", res.data);
        setStreamOption(res.data);
      })
      .catch((e) => {
        console.log("stream", e);
      });
  };
  const fetchSalary = async () => {
    axios
      .get(`${base_url}/generic/salary`)
      .then((res) => {
        console.log("Salary", res.data);
        setSalaryList(res.data);
      })
      .catch((e) => {
        console.log("salary", e);
      });
  };
  const fetchGender = async () => {
    axios
      .get(`${base_url}/generic/gender`)
      .then((res) => {
        console.log("gender", res.data);
        setGenderOption(res.data);
      })
      .catch((err) => {
        console.log("gender", err);
      });
  };
  const fetchEducation = async () => {
    axios
      .get(`${base_url}/generic/qualification`)
      .then((res) => {
        console.log("education", res.data);
        setEducationOption(res.data);
      })
      .catch((err) => {
        console.log("education", err);
      });
  };
  const fetchIndustry = async () => {
    axios
      .get(`${base_url}/generic/industry`)
      .then((res) => {
        console.log("industry", res.data);
        setIndustryOption(res.data);
      })
      .catch((err) => {
        console.log("industry", err);
      });
  };
  const fetchCaste = async () => {
    axios
      .get(`${base_url}/generic/caste`)
      .then((res) => {
        console.log("caste", res.data);
        setCasteOption(res.data);
      })
      .catch((err) => {
        console.log("caste", err);
      });
  };
  const fetchExperience = async () => {
    axios
      .get(`${base_url}/generic/experience`)
      .then((res) => {
        console.log("experience", res.data);
        const data = res.data;
        setExperienceOption(data);
        // setExperienceOption((data)=>{
        //   data.map((ele)=>({
        //     label: `${ele} Years`,
        //     value: ele
        //   }))
        // });
      })
      .catch((err) => {
        console.log("experience", err);
      });
  };
  const fetchJobRole = async () => {
    axios
      .get(`${base_url}/generic/designation`)
      .then((res) => {
        console.log("job_role", res.data);
        const data = res.data;
        setJobRoleList(data);
        // setExperienceOption((data)=>{
        //   data.map((ele)=>({
        //     label: `${ele} Years`,
        //     value: ele
        //   }))
        // });
      })
      .catch((err) => {
        console.log("job_role", err);
      });
  };
  const fetchJobLocationList = async () => {
    axios
      .get(`${base_url}/jobseeker/fetch_jf_location`)
      .then((res) => {
        console.log("job_location", res.data.job_fair_location);
        const data = res.data;
        setJobLocationList(data.job_fair_location);
        // setExperienceOption((data)=>{
        //   data.map((ele)=>({
        //     label: `${ele} Years`,
        //     value: ele
        //   }))
        // });
      })
      .catch((err) => {
        console.log("jf_location", err);
      });
  };
  const fetchMaritalStatus = async () => {
    axios
      .get(`${base_url}/generic/maritalstatus`)
      .then((res) => {
        console.log(res.data);
        setMaritalStatusList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchSkillsList = async () => {
    axios
      .get(`${base_url}/generic/skills`)
      .then((res) => {
        console.log(res.data);
        setSkillsList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchLink = async () => {
    // try {
    //   const response = await axios.post(
    //     "https://m1fk502650.execute-api.ap-south-1.amazonaws.com/uat/superadmin/dashboard/jobcenter/fetch/details",
    //     {
    //       registration_id: query1,
    //     }
    //   );
    //   const data = response.data;
    //   console.log(data);
    // } catch (err) {
    //   console.log(err);
    // }
    axios
      .post(
        "https://m1fk502650.execute-api.ap-south-1.amazonaws.com/uat/superadmin/dashboard/jobcenter/fetch/details",
        {
          registtration_id: query1,
        }
      )
      .then((res) => {
        console.log("link data ", res.data);
      })
      .catch((err) => {
        console.log("error ", err);
      });
  };

  useEffect(() => {
    // fetchCenterHead();
    // fetchCenterEmployee();
    // fetchCenters();
    fetchData1();
    //fetchData2()
    fetchGender();
    fetchEducation();
    fetchExperience();
    fetchCaste();
    fetchIndustry();
    fetchJobRole();
    fetchJobLocationList();
    fetchDegree();
    fetchStream();
    fetchSalary();
    fetchMaritalStatus();
    fetchSkillsList();
  }, []);

  const fetchlang = async () => {
    try {
      const res = await axios.get('https://samarth.prod.api.sapioglobal.com/jobseeker/account/language')
      const data = await res.data
      setLanguageList(data?.map((v) => {
        return {
          value: v,
          label: v
        }
      }))
    }
    catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    fetchState();
    fetchlang();
    // fetchQuesList();
  }, []);

  useEffect(() => {
    fetchDistrict();
  }, [selectedState]);
  useEffect(() => {
    fetchTaluka();
  }, [formDetails.district]);

  {
    /************ */
  }

  const addFields = (e) => {
    e.preventDefault();
    let newfield = {
      employer_name: "",
      designation: "",
      job_type: "",
      joining_date: "",
      relieving_date: "",
      totel_exp: null,
    };

    // setWorkExp([...workExp, newfield]);
  };

  // useEffect(() => {
  //   const fetchStateList = () => {
  //     axios
  //       .post(
  //         ` https://dashboardapi.mysba.globalsapio.com/State`,
  //         {
  //           level: 1,
  //           thislevel: "India",
  //           locale: "en",
  //         }
  //         // {
  //         //   headers: {
  //         //     Authorization: `Bearer ${token}`,
  //         //   },
  //         // }
  //       )
  //       .then((res) => {
  //         // console.log("State", res.data);
  //         // var StateList = res.data[0].sort().map(function (item) {
  //         //   return { label: item, value: item };
  //         // });
  //         setState(res.data);
  //       })
  //       .catch((e) => {
  //         console.log(e);
  //       });
  //   };
  //   fetchStateList();
  // }, []);

  // useEffect(() => {
  //   const fetchstate = () => {
  //     axios
  //       .post(
  //         `https://dashboardapi.mysba.globalsapio.com/District`,
  //         {
  //           level: 2,
  //           thislevel: formDetails.state,
  //           locale: "en",
  //         }
  //         // {
  //         //   headers: {
  //         //     Authorization: `Bearer ${token}`,
  //         //   },
  //         // }
  //       )
  //       .then((res) => {
  //         setDistrictList(res.data);

  //         //console.log("District", res.data);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   };
  //   //fetchStateList();
  //   fetchstate();
  // }, [formDetails]);

  const handleFormChange = (index, event) => {
    // let data = [...workExp];
    // data[index][event.target.name] = event.target.value;
    // setWorkExp(data);
  };
  const removeFields = (index) => {
    // let data = [...workExp];
    // data.splice(index, 1);
    // setWorkExp(data);
  };

  const addFields1 = (e) => {
    e.preventDefault();
    let newfield = {
      exam_passed: "",
      year: null,
      medium: "",
      percentage: null,
    };

    // setEducationQ([...educationQ, newfield]);
  };
  const handleFormChange1 = (index, event) => {
    // let data = [...educationQ];
    // data[index][event.target.name] = event.target.value;
    // setEducationQ(data);
  };
  const removeFields1 = (index) => {
    // let data = [...educationQ];
    // data.splice(index, 1);
    // setEducationQ(data);
  };

  const formOne = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstnm: "",
      lastnm: "",
      email: "",
      mobnum: "",
      birth_date: "",
      gender: "",
      marital_status: "",
      org_address: "",
      org_state: "",
      org_district: "",
      taluka: "",
      org_pincode: "",
    },
    validationSchema: Yup.object().shape({
      org_address: Yup.string().required("Please enter address"),
      org_state: Yup.string().required("Please select state"),
      org_district: Yup.string().required("Please select district"),
      org_pincode: Yup.string()
        .notRequired()
        .length(6, "Pincode should be 6 digit")
        .matches(/^[0-9]+$/, "Must be only digits"),
      //industry: Yup.string().required("Please select industry"),
      email: Yup.string()
        .notRequired()
        .email("Please enter valid email"),
      mobnum: Yup.string()
        .required("Please enter mobile number")
        .length(10, "Mobile number should be 10 digit")
        .matches(/^[0-9]+$/, "Must be only digits"),
      firstnm: Yup.string().required("Please enter first name"),
      lastnm: Yup.string().required("Please enter last name"),
      gender: Yup.string().required("Please select gender"),
      birth_date: Yup.string().required("Please enter date of birth")
    }),
    onSubmit: async (values) => {
      setOpen1(true)
      try {
        const params = {
          mobile: values.mobnum,
        };
        const paramString = new URLSearchParams(params).toString();
        const res = await axios.get(`${base_url}/jobseeker/fetch_jc_data?${paramString}`)
        if (res.data) {
          setOpen1(false)
        }

        Swal.fire({
          icon: "info",
          title: "",
          text: "Try new mobile number",
          showConfirmButton: false,
          timer: 2000,
        });
      }
      catch (err) {
        try {
          syncWait(2000)
          setObj({
            org_address: values.org_address,
            org_state: values.org_state,
            org_district: values.org_district,
            taluka: values.taluka,
            org_pincode: values.org_pincode,
            mobnum: values.mobnum,
            email: values.email,
            firstnm: values.firstnm,
            lastnm: values.lastnm,
            marital_status: values.marital_status,
            gender: values.gender,
            birth_date: values.birth_date
          })
          /*
          let formdata = new FormData()
          formdata.append("org_address", values.org_address)
          formdata.append("org_state", values.org_state)
          formdata.append("org_district", values.org_district)
          formdata.append("org_pincode", values.org_pincode)
          formdata.append("industry", "-")
          formdata.append("mobnum", values.mobnum)
          formdata.append("email", values.email)
          formdata.append("firstnm", values.firstnm)
          formdata.append("lastnm", values.lastnm)
          formdata.append("attested", 1)
          formdata.append("form_part", 1)
          */
          //setRegId(data['Developer Message'] ? data['Developer Message'] : "")

          setSubmitformOne(false)
          setOpen1(false)
          setNextStep(true)
          setShow(true)
          handleNext()
        }
        catch (err) {
          console.log(err)
          setOpen1(false)
          setNextStep(false)
          setSubmitformOne(false)
          Swal.fire({
            icon: "error",
            title: "Something went wrong",
            text: "Something went wrong.",
            //text: "Something went wrong.",
            showConfirmButton: false,
            timer: 3000,
          }).then(() => {
            setNextStep(false);
          });
        }
      }
    },
  });

  const formTwo = useFormik({
    initialValues: {
      highest_education_new: "",
      current_industry_new: "",
      current_salary_new: "",
      des_salary_new: "",
      job_role_new: "",
      self_opportunity: "",
      relocation: "",
      experience_new: "",
      stream_new: "",
    },
    validationSchema: Yup.object().shape({
      highest_education_new: Yup.string().required("Please select highest education"),
      current_industry_new: Yup.string().required("Please select current industry"),
      job_role_new: Yup.string().required("Please select job role"),
    }),
    onSubmit: async (values, { resetForm }) => {
      if (resumeshow === true && resumefile === "") {
        Swal.fire({
          icon: "error",
          html: '<b>Please upload your resume.</b>',
          showConfirmButton: false,
          width: matchsm ? '350' : '300',
          timer: 2000,
        });
      }
      else {
        try {
          let formdata = new FormData()
          formdata.append("highest_education", values.highest_education_new)
          formdata.append("stream", values.stream_new)
          formdata.append("current_industry", values.current_industry_new)
          formdata.append("skills", preffere.map((ele) => ele.value).toString())
          formdata.append("current_salary", values.current_salary_new)
          formdata.append("desired_salary", values.des_salary_new)
          formdata.append("job_role", values.job_role_new)
          formdata.append("languages", languagesall.map((ele) => ele.value).toString())
          formdata.append("self_opportunity", values.self_opportunity)
          formdata.append("relocation", values.relocation === "Yes" ? 1 : 0)
          formdata.append("experience", values.experience_new)
          formdata.append("reg_id", regId)
          formdata.append("resume_ready", resumeshow1)

          formdata.append("firstname", obj.firstnm);
          formdata.append("lastname", obj.lastnm);
          formdata.append("email", obj.email);
          formdata.append("date_of_birth", moment(obj.birth_date).format("YYYY-MM-DD"));
          formdata.append("gender", obj.gender);
          formdata.append("marital_status", obj.marital_status);
          formdata.append("state", obj.org_state);
          formdata.append("district", obj.org_district);
          formdata.append("taluka", obj.taluka);
          formdata.append("mobnum", obj.mobnum);
          formdata.append("address", obj.org_address);
          formdata.append("wa_optin", "true");
          formdata.append("resume", resumefile);
          formdata.append("datasource", cpId ? cpId : "JC_FORM");
          formdata.append("pincode", obj.org_pincode);
          cpId && formdata.append("jobid", jobId);
          cpId && formdata.append("orgid", orgId);
          !cpId && formdata.append("center_id", query1);
          !cpId && formdata.append("center_head", query2);
          !cpId && formdata.append("center_employee", query3);

          console.log(formdata, 'aaa')

          const res = await axios.post(`${uat_url}/channelpartner/dashboard/candidate/form`, formdata)

          const data = await res.data
          console.log(data)
          setSubmitform(false)
          resetForm()
          setSelectedState("")
          fetchLink()
          setOpen(true)
        }
        catch (err) {
          Swal.fire({
            icon: "error",
            title: err?.response?.data ? err.response.data['Developer Message'] : "Something went wrong",
            text: err?.response?.data ? err.response.data['User Message'] : "Something went wrong.",
            showConfirmButton: false,
            timer: 3000,
          });
        }
      }
    },
  });

  useEffect(() => {
    // axios
    //   .get("https://7e55uus5l5.execute-api.ap-south-1.amazonaws.com/dev/desg")
    //   .then((res) => {
    //     setDesi(
    //       Object.values(res.data.name).map((ele) => ({
    //         label: ele,
    //         value: ele,
    //       }))
    //     );
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
    axios
      .get(`${base_url}/desg`)
      .then((res) => {
        setDesi(
          Object.values(res.data.name).map((ele) => ({
            label: ele,
            value: ele,
          }))
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const query = searchParams.get("location");
  console.log(query);

  const handleResume = (File) => {
    const validExt = ["pdf", "doc"];
    const fileExt = File.name.split(".")[1];
    return validExt.includes(fileExt);
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (handleResume(file) && file.size / 1024 < 5120) {
      setResumeFile(file);
    } else {
      Swal.fire({
        icon: "error",
        html: '<b>Please upload files < 5mb and make sure format is either pdf or doc.</b>',
        showConfirmButton: false,
        width: matchsm ? '350' : '300',
        timer: 3000,
      });
      ref.current.value = "";
      setResumeFile("");
    }
  };

  const params = {};
  searchParams.forEach((value, key) => {
    params[key] = value;
  });
  console.log(params);
  // const query1 = searchParams.get("centerid");
  // const query2 = searchParams.get("centerhead");
  // const query3 = searchParams.get("centeremployee");
  const query1 = searchParams.get("jcid");
  const query2 = searchParams.get("chid");
  const query3 = searchParams.get("ceid");
  const cpId = searchParams.get("cpid");
  const jobId = searchParams.get("jobid");
  const orgId = searchParams.get("orgid");
  console.log("query ", query1, query2, query3);

  const checkMobileNum = (number) => {
    const params = {
      mobile: number,
    };
    const paramString = new URLSearchParams(params).toString();
    axios
      .get(`${base_url}/jobseeker/fetch_jc_data?${paramString}`)
      .then((res) => {
        Swal.fire({
          icon: "info",
          title: "",
          text: "Try new mobile number",
          showConfirmButton: false,
          timer: 2000,
        });
      })
      .catch((err) => {
        console.log("New user", err);
        handleSubmit();
        // setVerifyStatus(false);
        // return false;
        // Swal.fire({
        //   icon: "error",
        //   title: "",
        //   text: err.data,
        //   showConfirmButton: false,
        //   timer: 2000,
        // });
      });
  };



  const handleSubmit = async () => {
    setIsLoading(true);
    let formData = new FormData();
    // formData.append("location", query);
    formData.append("firstname", formDetails.firstname);
    formData.append("lastname", formDetails.lastname);
    //formData.append("fathername", formDetails.fathername);
    //formData.append("mothername", formDetails.mothername);
    formData.append("email", formDetails.email);
    formData.append(
      "date_of_birth",
      moment(formDetails.date_of_birth).format("YYYY-MM-DD")
      // formDetails.date_of_birth !== ""
      //   ? formDetails.date_of_birth.split("-").reverse().join("-")
      //   : ""
    );
    formData.append("gender", formDetails.gender);
    formData.append("marital_status", formDetails.marital_status);
    formData.append("state", formDetails.state);
    //formData.append("part_time", formDetails.part_time);
    formData.append("district", formDetails.district);
    formData.append("taluka", formDetails.taluka);
    // formData.append("know_us", formDetails.know_us);
    formData.append("self_opportunity", formDetails.self_opportunity);
    formData.append("highest_education", formDetails.highest_education);
    formData.append("stream", formDetails.stream);
    formData.append("mobnum", formDetails.mobile);
    //formData.append("caste_category", formDetails.cast);
    // formData.append("aadhar", formDetails.aadhar);
    formData.append("current_industry", formDetails.current_industry);
    formData.append("address", formDetails.address);
    // formData.append(
    //   "preffered_work",
    //   preffere.map((ele) => ele.value).toString()
    // );
    formData.append("skills", preffere.map((ele) => ele.value).toString());
    formData.append("experience", formDetails.experience);
    formData.append("des_industry", formDetails.des_industry);
    formData.append("wa_optin", "true");
    formData.append("languages", languagesall.map((ele) => ele.value).toString());
    // formData.append(
    //   "languages",
    //   "Hindi English"
    // );
    formData.append("desired_salary", formDetails.desired_salary);
    formData.append("current_salary", formDetails.current_salary);
    formData.append("resume", resumefile);
    // formData.append("datetime", new Date());
    // formData.append("date", newdate);
    // formData.append("time", new Date().toLocaleTimeString());
    formData.append("datasource", cpId ? cpId : "JC_FORM");
    formData.append("pincode", formDetails.pincode);
    // formData.append("center_id",formDetails.center_id);
    // formData.append("center_head", formDetails.center_head);
    formData.append("job_role", formDetails.job_role);
    cpId && formData.append("jobid", jobId);
    cpId && formData.append("orgid", orgId);
    !cpId && formData.append("center_id", query1);
    !cpId && formData.append("center_head", query2);
    !cpId && formData.append("center_employee", query3);

    ///
    {
      /*
      formData.append("pincode",formDetails.pincode)
      formData.append("datasource","JC_FORM");
      formData.append("center_id","JC10"),
    formData.append("center_head", "CH2"),
    formData.append("center_employee", "CE5",)
    */
    }

    await axios
      .post(
        // " https://7e55uus5l5.execute-api.ap-south-1.amazonaws.com/dev/mysbajobfair",
        // `${uat_url}/jobseeker/register/job_center`,
        `${uat_url}/channelpartner/dashboard/candidate/form`,
        formData
        // {
        //   firstname: formDetails.firstname,
        //   lastname: formDetails.lastname,
        //   fathername: formDetails.fathername,
        //   mothername: formDetails.mothername,
        //   // height:formDetails.height,
        //   email: formDetails.email,
        //   date_of_birth: formDetails.date_of_birth,
        //   gender: formDetails.gender,
        //   marital_status: formDetails.marital_status,
        //   State: formDetails.state,
        //   // mother_tongue:formDetails.mother_tongue,
        //   // stay_mah:formDetails.stay_mah,
        //   part_time: formDetails.part_time,
        //   district: formDetails.district,
        //   taluka: formDetails.taluka,
        //   know_us: formDetails.know_us,
        //   // religion:formDetails.religion,
        //   qualification: formDetails.qualification,
        //   stream: formDetails.stream,
        //   mobile: formDetails.mobile,
        //   cast: formDetails.cast,
        //   // sub_cast: formDetails.sub_cast,
        //   aadhar: formDetails.aadhar,
        //   // priority:formDetails.priority,
        //   // job_fair_location: formDetails.job_Location,
        //   current_industry: formDetails.current_industry,
        //   address: formDetails.address,
        //   preffered_work: preffere.map((ele) => ele.value).toString(),
        //   experience: formDetails.experience,
        //   des_industry: formDetails.des_industry,
        //   // if_disabled:formDetails.if_disabled,
        //   // ex_serviceman:formDetails.ex_serviceman,
        //   wa_optin: check ? 1 : 0,
        //   // experience: workExp,
        //   // qualification_and_training: educationQ,
        //   // wiling_for:formDetails.wiling_for,
        //   languages: formDetails.languages,
        // }
      )
      .then((res) => {
        // console.log(res.response.data, "Line 78")
        // alert("form Submitted");
        setIsLoading(false);
        fetchLink();
        Swal.fire({
          icon: "success",
          title: "",
          text: "Details Submited Successfully",
          showConfirmButton: false,
          timer: 2000,
        });
        setFormDetails({
          firstname: "",
          lastname: "",
          //fathername: "",
          //mothername: "",
          religion: "",
          mother_tongue: "",
          email: "",
          date_of_birth: "",
          gender: "",
          height: "",
          address: "",
          state: "",
          priority: "",
          district: "",
          taluka: "",
          desired_salary: "",
          highest_education: "",
          des_industry: "",
          stream: "",
          mobile: "",
          cast: "",
          job_Location: "",
          // sub_cast: "",
          marital_status: "",
          aadhar: "",
          know_us: "",
          part_time: "",
          // job_fair_location: "",
          current_industry: "",
          preffered_work: "",
          wa_optin: "false",
          self_opportunity: "",
          // center_id:"",
          // center_head:"",
          // center_employee:"",
          experience: "",
          // stay_mah: {
          //   year: null,
          //   month: null,
          // },
          // if_disabled: {
          //   nature: "",
          //   percentage: null,
          // },
          // ex_serviceman: {
          //   type: "",
          //   rank: "",
          //   total_service: null,
          // },
          languages: {
            marathi: {
              speak: false,
              read: false,
              write: false,
              fluency: false,
            },
            hindi: {
              speak: false,
              read: false,
              write: false,
              fluency: false,
            },
            english: {
              speak: false,
              read: false,
              write: false,
              fluency: false,
            },
          },
          // wiling_for: {
          //   state_police_service: false,
          //   state_reserve_police_force: false,
          //   armed_force: false,
          //   private_security_services: false,
          //   sales_service: false,
          //   type_of_agents: false,
          //   sweeper_cleaner: false,
          //   shop_customer_services: false,
          // },
        });
        //setCheck(true);
        // setjobLocation([]);
        setResumeFile("");
        setPreffere([]);
        setLanguagesall([])
        // setWorkExp([
        //   {
        //     employer_name: "",
        //     designation: "",
        //     job_type: "",
        //     joining_date: "",
        //     relieving_date: "",
        //     totel_exp: null,
        //   },
        // ]);
        // setEducationQ([
        //   {
        //     exam_passed: "",
        //     year: null,
        //     medium: "",
        //     percentage: null,
        //   },
        // ]);
      })

      .catch((err) => {
        console.log(err.response.data["Developer Message"]);
        setIsLoading(false);
        Swal.fire({
          icon: "error",
          title: "",
          text: err.response.data["Developer Message"],
          showConfirmButton: false,
          timer: 2000,
        });
      });
  };

  // useEffect( ()=>{()
  //   if($('.select').length > 0) {
  //     $('.select').select2({
  //       minimumResultsForSearch: -1,
  //       width: '100%'
  //     });
  //   }
  // });

  const options = [
    {
      label: "Favourites",
      options: fav,
    },
    {
      label: "Others",
      options: desi,
    },
    // { label: "A root option", value: "value_3" },
    // { label: "Another root option", value: "value_4" },
  ];
  return (
    <>
      <div>
        <div>
          <img
            style={{ width: "100%", height: "auto" }}
            src={cover1}
            className="logo-img"
          />
          <img
            style={{ width: "100%", height: "auto" }}
            src={cover1}
            className="logo-img1"
          />
        </div>

        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', flexDirection: 'column' }}>
          <div className="SDiv">
            {show ?
              <>
                <SBox2>
                  {/*
                  <Grid container sx={{ m: 2, mb: 1 }}>
                    <Grid item lg={4.75} md={4.75} sm={4} xs={2.5} />

                    <Grid item lg={2.5} md={2.5} sm={4} xs={7}>
                      <SBoxN1>
                        <STypography2 variant='body1'>
                          Registration
                        </STypography2>
                      </SBoxN1>
                    </Grid>

                    <Grid item lg={4.75} md={4.75} sm={4} xs={2.5}>
                      <SBoxN2>
                        {matchsm ?
                          <img src={img1} style={{ height: '53.13px', margin: '8px' }} alt="" /> :
                          null
                        }
                      </SBoxN2>
                    </Grid>
                  </Grid>
                  */}

                  <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', m: 1 }}>
                    <STypography2 variant='body1'>
                      Registration
                    </STypography2>

                    {matchsm ?
                      <img src={img1} style={{ height: '53.13px', margin: '8px' }} alt="" /> :
                      null
                    }
                  </Box>

                  <Box sx={{ maxWidth: '700px', minWidth: '200px', ml: 'auto', mr: 'auto', mb: 2 }}>
                    <Stepper activeStep={activeStep} sx={stepperStyle}>
                      {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        if (isStepSkipped(index)) {
                          stepProps.completed = false;
                        }
                        return (
                          <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>
                              <STypography7 variant="body2">
                                {label}
                              </STypography7>
                            </StepLabel>
                          </Step>
                        );
                      })}
                    </Stepper>
                  </Box>

                  <Grid container spacing={2}>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant='body1'>
                          Highest Education <sup style={{ color: 'red', fontSize: '8px' }}>*</sup>
                        </STypography4>

                        <Box sx={{ mt: 1 }}>
                          <Select
                            placeholder="Select"
                            options={educationOption}
                            onChange={(e) => {
                              formTwo.setFieldValue("highest_education_new", e.value);
                            }}
                            value={{ label: formTwo.values.highest_education_new }}
                            id='highest_education_new'
                            name='highest_education_new'
                            styles={{
                              placeholder: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: 'Inter',
                                color: 'rgba(0, 0, 0, 0.3)'
                              }),
                              menu: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: 'Inter',
                                fontSize: '15px',
                                textAlign: 'center'
                              }),
                            }}
                          />
                        </Box>

                        {formTwo.touched.highest_education_new &&
                          formTwo.errors.highest_education_new ? (
                          <STypography5 variant='caption'>
                            {formTwo.errors.highest_education_new}
                          </STypography5>
                        ) : null}
                      </SBox4>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant='body1'>
                          Stream
                        </STypography4>

                        <Box sx={{ mt: 1 }}>
                          <Select
                            placeholder="Select"
                            options={streamOption}
                            onChange={(e) => {
                              formTwo.setFieldValue("stream_new", e.value);
                            }}
                            value={{ label: formTwo.values.stream_new }}
                            id='stream_new'
                            name='stream_new'
                            styles={{
                              placeholder: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: 'Inter',
                                color: 'rgba(0, 0, 0, 0.3)'
                              }),
                              menu: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: 'Inter',
                                fontSize: '15px',
                                textAlign: 'center'
                              }),
                            }}
                          />
                        </Box>
                      </SBox4>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant='body1'>
                          Current Industry <sup style={{ color: 'red', fontSize: '8px' }}>*</sup>
                        </STypography4>

                        <Box sx={{ mt: 1 }}>
                          <Select
                            placeholder="Select"
                            options={industryOption}
                            onChange={(e) => {
                              formTwo.setFieldValue("current_industry_new", e.value);
                            }}
                            value={{ label: formTwo.values.current_industry_new }}
                            id='current_industry_new'
                            name='current_industry_new'
                            styles={{
                              placeholder: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: 'Inter',
                                color: 'rgba(0, 0, 0, 0.3)'
                              }),
                              menu: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: 'Inter',
                                fontSize: '15px',
                                textAlign: 'center'
                              }),
                            }}
                          />
                        </Box>

                        {formTwo.touched.current_industry_new &&
                          formTwo.errors.current_industry_new ? (
                          <STypography5 variant='caption'>
                            {formTwo.errors.current_industry_new}
                          </STypography5>
                        ) : null}
                      </SBox4>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant='body1'>
                          Experience
                        </STypography4>

                        <Box sx={{ mt: 1 }}>
                          <Select
                            placeholder="Select"
                            options={experienceOption}
                            onChange={(e) => {
                              formTwo.setFieldValue("experience_new", e.value);
                            }}
                            value={{ label: formTwo.values.experience_new }}
                            id='experience_new'
                            name='experience_new'
                            styles={{
                              placeholder: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: 'Inter',
                                color: 'rgba(0, 0, 0, 0.3)'
                              }),
                              menu: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: 'Inter',
                                fontSize: '15px',
                                textAlign: 'center'
                              }),
                            }}
                          />
                        </Box>
                      </SBox4>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant='body1'>
                          Skills
                        </STypography4>

                        <Box sx={{ mt: 1 }}>
                          <Select
                            placeholder=""
                            options={skillsList}
                            isMulti
                            value={preffere}
                            onChange={(e) => {
                              if (e) {
                                setPreffere(e.map((item) => item));
                              } else {
                                setPreffere([]);
                              }
                            }}
                            styles={{
                              placeholder: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: 'Inter',
                                color: 'rgba(0, 0, 0, 0.3)'
                              }),
                              menu: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: 'Inter',
                                fontSize: '15px',
                                textAlign: 'center'
                              }),
                            }}
                          />
                        </Box>
                      </SBox4>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant='body1'>
                          Current Salary
                        </STypography4>

                        <Box sx={{ mt: 1 }}>
                          <Select
                            placeholder="Select"
                            options={salaryList}
                            onChange={(e) => {
                              formTwo.setFieldValue("current_salary_new", e.value);
                            }}
                            value={{ label: formTwo.values.current_salary_new }}
                            id='current_salary_new'
                            name='current_salary_new'
                            styles={{
                              placeholder: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: 'Inter',
                                color: 'rgba(0, 0, 0, 0.3)'
                              }),
                              menu: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: 'Inter',
                                fontSize: '15px',
                                textAlign: 'center'
                              }),
                            }}
                          />
                        </Box>
                      </SBox4>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant='body1'>
                          Desired Salary
                        </STypography4>

                        <Box sx={{ mt: 1 }}>
                          <Select
                            placeholder="Select"
                            options={salaryList}
                            onChange={(e) => {
                              formTwo.setFieldValue("des_salary_new", e.value);
                            }}
                            value={{ label: formTwo.values.des_salary_new }}
                            id='des_salary_new'
                            name='des_salary_new'
                            styles={{
                              placeholder: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: 'Inter',
                                color: 'rgba(0, 0, 0, 0.3)'
                              }),
                              menu: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: 'Inter',
                                fontSize: '15px',
                                textAlign: 'center'
                              }),
                            }}
                          />
                        </Box>
                      </SBox4>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant='body1'>
                          Desired Job Role <sup style={{ color: 'red', fontSize: '8px' }}>*</sup>
                        </STypography4>

                        <Box sx={{ mt: 1 }}>
                          <Select
                            placeholder="Select"
                            options={jobRoleList}
                            onChange={(e) => {
                              formTwo.setFieldValue("job_role_new", e.value);
                            }}
                            value={{ label: formTwo.values.job_role_new }}
                            id='job_role_new'
                            name='job_role_new'
                            styles={{
                              placeholder: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: 'Inter',
                                color: 'rgba(0, 0, 0, 0.3)'
                              }),
                              menu: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: 'Inter',
                                fontSize: '15px',
                                textAlign: 'center'
                              }),
                            }}
                          />
                        </Box>

                        {formTwo.touched.job_role_new &&
                          formTwo.errors.job_role_new ? (
                          <STypography5 variant='caption'>
                            {formTwo.errors.job_role_new}
                          </STypography5>
                        ) : null}
                      </SBox4>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant='body1'>
                          Languages Known
                        </STypography4>

                        <Box sx={{ mt: 1 }}>
                          <Select
                            isMulti
                            placeholder=""
                            options={languageList}
                            value={languagesall}
                            onChange={(e) => {
                              if (e) {
                                setLanguagesall(e.map((item) => item));
                              } else {
                                setLanguagesall([]);
                              }
                            }}
                            styles={{
                              placeholder: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: 'Inter',
                                color: 'rgba(0, 0, 0, 0.3)'
                              }),
                              menu: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: 'Inter',
                                fontSize: '15px',
                                textAlign: 'center'
                              })
                            }}
                          />
                        </Box>
                      </SBox4>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant='body1'>
                          Interested in self employement opportunity?
                        </STypography4>

                        <Box sx={{ mt: 1 }}>
                          <Select
                            placeholder="Select"
                            options={opportunities}
                            onChange={(e) => {
                              formTwo.setFieldValue("self_opportunity", e.value);
                            }}
                            value={{ label: formTwo.values.self_opportunity }}
                            id='self_opportunity'
                            name='self_opportunity'
                            styles={{
                              placeholder: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: 'Inter',
                                color: 'rgba(0, 0, 0, 0.3)'
                              }),
                              menu: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: 'Inter',
                                fontSize: '15px',
                                textAlign: 'center'
                              }),
                            }}
                          />
                        </Box>
                      </SBox4>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant='body1'>
                          Ready to relocate?
                        </STypography4>

                        <Box sx={{ mt: 1 }}>
                          <Select
                            placeholder="Select"
                            options={opportunities}
                            onChange={(e) => {
                              formTwo.setFieldValue("relocation", e.value);
                            }}
                            value={{ label: formTwo.values.relocation }}
                            id='relocation'
                            name='relocation'
                            styles={{
                              placeholder: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: 'Inter',
                                color: 'rgba(0, 0, 0, 0.3)'
                              }),
                              menu: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: 'Inter',
                                fontSize: '15px',
                                textAlign: 'center'
                              }),
                            }}
                          />
                        </Box>
                      </SBox4>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant='body1'>
                          Do you have your resume?
                        </STypography4>

                        <Box sx={{ mt: 1 }}>
                          <Select
                            placeholder=""
                            options={opportunities}
                            onChange={(e) => {
                              setResumeshow(e ? e.label === "Yes" ? true : false : false)
                              setResumeshow1(e ? e.label === "Yes" ? 1 : 0 : 0)
                            }}
                            styles={{
                              placeholder: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: 'Inter',
                                color: 'rgba(0, 0, 0, 0.3)'
                              }),
                              menu: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: 'Inter',
                                fontSize: '15px',
                                textAlign: 'center'
                              }),
                            }}
                          />
                        </Box>
                      </SBox4>
                    </Grid>
                  </Grid>


                  <br />

                  {resumeshow ?
                    <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                      <input
                        id="imgupload"
                        ref={ref}
                        style={{ display: "none" }}
                        type="file"
                        accept=".pdf, .doc, .docx"
                        onChange={handleFileUpload}
                      />
                      <label htmlFor="imgupload">
                        <SBox6>
                          <img src={img2} height={"60px"} alt="" />

                          <STypography8 variant="body1">
                            Choose File+
                          </STypography8>

                          <STypography9 variant="body1">
                            {"Please upload files < 5mb and make sure format is either pdf or doc."}
                          </STypography9>
                        </SBox6>
                      </label>
                    </Box> :
                    null
                  }

                  {resumeshow === false ?
                    <STypography10 variant="body1">
                      "If you already have a CV, please upload. If not, we will prepare your Saksham powered CV and may share upon request."
                    </STypography10> :
                    null
                  }

                  <Box sx={{ pt: 1, pb: 1, textAlign: 'center' }}>
                    <STypography4 variant="p">
                      <input
                        type="checkbox"
                        name="checkbox"
                        checked={true}
                      />&emsp;

                      <img
                        src={watsapp}
                        style={{ height: "30px", width: "30px" }}
                      />

                      &nbsp;I give the consent to share my details and I allow to send me further details and updates over WhatsApp.
                    </STypography4>
                  </Box>

                  <Box sx={{ p: 1, mt: 1 }}>
                    <SButton1 onClick={formTwo.handleSubmit}>
                      <STypography6 variant="body1">
                        Submit
                      </STypography6>
                    </SButton1>

                    <SButton2 onClick={() => {
                      setShow(false);
                      setSubmitform(false);
                      formTwo.handleReset();
                      //formOne.handleReset();
                      setNextStep(false);
                      handleReset()
                      setResumeFile("")
                      setResumeshow(false)
                      setResumeshow1(0)
                      setLanguagesall([])
                      setPreffere([])
                    }}
                    >
                      <STypography6 variant="body1">
                        Cancel
                      </STypography6>
                    </SButton2>
                  </Box>
                </SBox2>
              </> :
              <>
                <SBox2>
                  {/*
                  <Grid container sx={{ m: 2, mb: 1 }}>
                    <Grid item lg={4.75} md={4.75} sm={4} xs={2.5} />

                    <Grid item lg={2.5} md={2.5} sm={4} xs={7}>
                      <SBoxN1>
                        <STypography2 variant='body1'>
                          Registration
                        </STypography2>
                      </SBoxN1>
                    </Grid>

                    <Grid item lg={4.75} md={4.75} sm={4} xs={2.5}>
                      <SBoxN2>
                        {matchsm ?
                          <img src={img1} style={{ height: '53.13px', margin: '8px' }} alt="" /> :
                          null
                        }
                      </SBoxN2>
                    </Grid>
                  </Grid>
                  */}

                  <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', m: 1 }}>
                    <STypography2 variant='body1'>
                      Registration
                    </STypography2>

                    {matchsm ?
                      <img src={img1} style={{ height: '53.13px', margin: '8px' }} alt="" /> :
                      null
                    }
                  </Box>

                  <Box sx={{ maxWidth: '700px', minWidth: '200px', ml: 'auto', mr: 'auto', mb: 2 }}>
                    <Stepper activeStep={activeStep} sx={stepperStyle}>
                      {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        if (isStepSkipped(index)) {
                          stepProps.completed = false;
                        }
                        return (
                          <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>
                              <STypography7 variant="body2">
                                {label}
                              </STypography7>
                            </StepLabel>
                          </Step>
                        );
                      })}
                    </Stepper>
                  </Box>

                  <Grid container spacing={2}>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant='body1'>
                          First Name <sup style={{ color: 'red', fontSize: '8px' }}>*</sup>
                        </STypography4>

                        <TextField
                          disabled={nextStep}
                          type="text"
                          fullWidth
                          id="firstnm"
                          name="firstnm"
                          size="small"
                          onChange={formOne.handleChange}
                          value={formOne.values.firstnm}
                        />

                        {formOne.touched.firstnm &&
                          formOne.errors.firstnm ? (
                          <STypography5 variant='caption'>
                            {formOne.errors.firstnm}
                          </STypography5>
                        ) : null}
                      </SBox4>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant='body1'>
                          Last Name <sup style={{ color: 'red', fontSize: '8px' }}>*</sup>
                        </STypography4>

                        <TextField
                          disabled={nextStep}
                          type="text"
                          fullWidth
                          id="lastnm"
                          name="lastnm"
                          size="small"
                          onChange={formOne.handleChange}
                          value={formOne.values.lastnm}
                        />

                        {formOne.touched.lastnm &&
                          formOne.errors.lastnm ? (
                          <STypography5 variant='caption'>
                            {formOne.errors.lastnm}
                          </STypography5>
                        ) : null}
                      </SBox4>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant='body1'>
                          Email
                        </STypography4>

                        <TextField
                          disabled={nextStep}
                          type="email"
                          fullWidth
                          id="email"
                          name="email"
                          size="small"
                          onChange={formOne.handleChange}
                          value={formOne.values.email}
                        />

                        {formOne.touched.email &&
                          formOne.errors.email ? (
                          <STypography5 variant='caption'>
                            {formOne.errors.email}
                          </STypography5>
                        ) : null}
                      </SBox4>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant='body1'>
                          Mobile Number <sup style={{ color: 'red', fontSize: '8px' }}>*</sup>
                        </STypography4>

                        <TextField
                          disabled={nextStep}
                          type="tel"
                          fullWidth
                          id="mobnum"
                          name="mobnum"
                          size="small"
                          inputProps={{
                            pattern:
                              "/^([+]?[s0-9]+)?(d{3}|[(]?[0-9]+[)])?([-]?[s]?[0-9])+$/",
                            maxLength: "10",
                            minLength: "10",
                          }}
                          onChange={formOne.handleChange}
                          value={formOne.values.mobnum}
                        />

                        {formOne.touched.mobnum &&
                          formOne.errors.mobnum ? (
                          <STypography5 variant='caption'>
                            {formOne.errors.mobnum}
                          </STypography5>
                        ) : null}
                      </SBox4>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant='body1'>
                          Date of Birth <sup style={{ color: 'red', fontSize: '8px' }}>*</sup>
                        </STypography4>

                        <TextField
                          disabled={nextStep}
                          type="date"
                          fullWidth
                          id="birth_date"
                          name="birth_date"
                          size="small"
                          onChange={formOne.handleChange}
                          value={formOne.values.birth_date}
                        />

                        {formOne.touched.birth_date &&
                          formOne.errors.birth_date ? (
                          <STypography5 variant='caption'>
                            {formOne.errors.birth_date}
                          </STypography5>
                        ) : null}
                      </SBox4>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant='body1'>
                          Gender <sup style={{ color: 'red', fontSize: '8px' }}>*</sup>
                        </STypography4>

                        <Box sx={{ mt: 1 }}>
                          <Select
                            isDisabled={nextStep}
                            placeholder="Select"
                            options={genderOption}
                            onChange={(e) => {
                              formOne.setFieldValue("gender", e.value);
                            }}
                            value={{ label: formOne.values.gender }}
                            id='gender'
                            name='gender'
                            styles={{
                              placeholder: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: 'Inter',
                                color: 'rgba(0, 0, 0, 0.3)'
                              }),
                              menu: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: 'Inter',
                                fontSize: '15px',
                                textAlign: 'center'
                              }),
                            }}
                          />
                        </Box>

                        {formOne.touched.gender &&
                          formOne.errors.gender ? (
                          <STypography5 variant='caption'>
                            {formOne.errors.gender}
                          </STypography5>
                        ) : null}
                      </SBox4>
                    </Grid>


                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant='body1'>
                          Marital Status
                        </STypography4>
                        <Select
                          isDisabled={nextStep}
                          placeholder="Select"
                          options={maritalstatusList}
                          onChange={(e) => {
                            formOne.setFieldValue("marital_status", e.value);
                          }}
                          value={{ label: formOne.values.marital_status }}
                          id="marital_status"
                          name="marital_status"
                          styles={{
                            placeholder: (baseStyles, state) => ({
                              ...baseStyles,
                              fontFamily: 'Inter',
                              color: 'rgba(0, 0, 0, 0.3)'
                            }),
                            menu: (baseStyles, state) => ({
                              ...baseStyles,
                              fontFamily: 'Inter',
                              fontSize: '15px',
                              textAlign: 'center'
                            }),
                          }}
                        />
                      </SBox4>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant='body1'>
                          Address <sup style={{ color: 'red', fontSize: '8px' }}>*</sup>
                        </STypography4>

                        <TextField
                          disabled={nextStep}
                          type="text"
                          fullWidth
                          id="org_address"
                          name="org_address"
                          size="small"
                          onChange={formOne.handleChange}
                          value={formOne.values.org_address}
                        />

                        {formOne.touched.org_address &&
                          formOne.errors.org_address ? (
                          <STypography5 variant='caption'>
                            {formOne.errors.org_address}
                          </STypography5>
                        ) : null}
                      </SBox4>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant='body1'>
                          State <sup style={{ color: 'red', fontSize: '8px' }}>*</sup>
                        </STypography4>

                        <Box sx={{ mt: 1 }}>
                          <Select
                            isDisabled={nextStep}
                            placeholder="Select"
                            options={State}
                            onChange={(e) => {
                              formOne.setFieldValue("org_state", e.value);
                              setSelectedState(e.value);
                            }}
                            value={{ label: formOne.values.org_state }}
                            id='org_state'
                            name='org_state'
                            styles={{
                              placeholder: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: 'Inter',
                                color: 'rgba(0, 0, 0, 0.3)'
                              }),
                              menu: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: 'Inter',
                                fontSize: '15px',
                                textAlign: 'center'
                              }),
                            }}
                          />
                        </Box>

                        {formOne.touched.org_state &&
                          formOne.errors.org_state ? (
                          <STypography5 variant='caption'>
                            {formOne.errors.org_state}
                          </STypography5>
                        ) : null}
                      </SBox4>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant='body1'>
                          District <sup style={{ color: 'red', fontSize: '8px' }}>*</sup>
                        </STypography4>

                        <Box sx={{ mt: 1 }}>
                          <Select
                            isDisabled={nextStep}
                            placeholder="Select"
                            options={District}
                            onChange={(e) => {
                              formOne.setFieldValue("org_district", e.value);
                            }}
                            value={{ label: formOne.values.org_district }}
                            id='org_district'
                            name='org_district'
                            styles={{
                              placeholder: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: 'Inter',
                                color: 'rgba(0, 0, 0, 0.3)'
                              }),
                              menu: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: 'Inter',
                                fontSize: '15px',
                                textAlign: 'center'
                              }),
                            }}
                          />
                        </Box>

                        {formOne.touched.org_district &&
                          formOne.errors.org_district ? (
                          <STypography5 variant='caption'>
                            {formOne.errors.org_district}
                          </STypography5>
                        ) : null}
                      </SBox4>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant='body1'>
                          Taluka
                        </STypography4>

                        <TextField
                          disabled={nextStep}
                          type="text"
                          fullWidth
                          id="taluka"
                          name="taluka"
                          size="small"
                          onChange={formOne.handleChange}
                          value={formOne.values.taluka}
                        />

                        {formOne.touched.taluka &&
                          formOne.errors.taluka ? (
                          <STypography5 variant='caption'>
                            {formOne.errors.taluka}
                          </STypography5>
                        ) : null}
                      </SBox4>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant='body1'>
                          Pin Code
                        </STypography4>

                        <TextField
                          disabled={nextStep}
                          type="tel"
                          inputProps={{
                            pattern:
                              "/^([+]?[s0-9]+)?(d{3}|[(]?[0-9]+[)])?([-]?[s]?[0-9])+$/",
                            maxLength: "6",
                            minLength: "6",
                          }}
                          fullWidth
                          id="org_pincode"
                          name="org_pincode"
                          size="small"
                          onChange={formOne.handleChange}
                          value={formOne.values.org_pincode}
                        />

                        {formOne.touched.org_pincode &&
                          formOne.errors.org_pincode ? (
                          <STypography5 variant='caption'>
                            {formOne.errors.org_pincode}
                          </STypography5>
                        ) : null}
                      </SBox4>
                    </Grid>
                  </Grid>

                  <Box sx={{ p: 1, mt: 1 }}>
                    <SButton1
                      onClick={nextStep ?
                        () => { setShow(true) } :
                        () => { setSubmitformOne(true); formOne.handleSubmit() }}

                    //onClick={() => { setShow(true); handleNext() }}
                    >
                      <STypography6 variant="body1">
                        Proceed to Next Step <ArrowForwardIcon />
                      </STypography6>
                    </SButton1>

                    <SButton2 onClick={() => { formOne.handleReset(); setSubmitformOne(false); setNextStep(false); handleReset() }}>
                      <STypography6 variant="body1">
                        Cancel
                      </STypography6>
                    </SButton2>
                  </Box>
                </SBox2>
              </>
            }
          </div>
        </Box>

        <Dialog
          open={open}
          //onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent sx={matchsm ? { textAlign: 'center', width: '350px' } : { textAlign: 'center', maxWidth: '350px' }}>
            <Box sx={matchsm ? { width: '300px', m: 2, ml: 'auto', mr: 'auto', p: 1, border: '3px dashed #A7A7A7', minHeight: '300px' } : { maxWidth: '300px', m: 2, ml: 'auto', mr: 'auto', p: 1, border: '3px dashed #A7A7A7', minHeight: '300px' }}>
              <img src={img4} height={"70px"} alt="" />
              <STypography11 variant="body2">
                Form submited successfully
              </STypography11>
              <hr />

              <STypography4 variant="body1">
                Thank you for registering with us!
              </STypography4>

              <SButton3 onClick={() => {
                handleClose();
                window.location.reload();
                setObj({
                  org_address: "",
                  org_state: "",
                  org_district: "",
                  taluka: "",
                  org_pincode: "",
                  mobnum: "",
                  email: "",
                  firstnm: "",
                  lastnm: "",
                  marital_status: "",
                  gender: "",
                  birth_date: ""
                })
              }}>
                <STypography6 variant="body1">
                  Go back to form <ArrowForwardIcon />
                </STypography6>
              </SButton3>
            </Box>
          </DialogContent>
        </Dialog>


        <Dialog
          open={open1}
          //onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent sx={matchsm ? { textAlign: 'center', width: '350px', } : { textAlign: 'center', maxWidth: '350px' }}>
            <Box sx={matchsm ? { width: '300px', m: 2, ml: 'auto', mr: 'auto', p: 1, border: '3px dashed #A7A7A7', minHeight: '300px' } : { maxWidth: '300px', m: 2, ml: 'auto', mr: 'auto', p: 1, border: '3px dashed #A7A7A7', minHeight: '300px' }}>
              <img src={img4} height={"70px"} alt="" />
              <STypography11 variant="body2">
                Details saved successfully
              </STypography11>
              <hr />

              <STypography4 variant="body1">
                “You are just one step away to start your journey”
              </STypography4>

              <STypography9 variant="body1">
                Please do not close the window or go back
              </STypography9>
            </Box>
          </DialogContent>
        </Dialog>
      </div >
    </>
  );
};
export default HorizontalForm;
